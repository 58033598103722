.pokemon {
  align-items: center;
  border: 1px gray solid;
  border-radius: 10px;
  display: flex;
  margin: auto;
  min-width: 25%;
  width: fit-content;
}

.pokemon-overview {
  margin: 10px;
}

.favorite-icon {
  align-self: flex-start;
  margin: 10px;
  width: 20px;
}
